import React from "react";

class Section_header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    let { title, description, color_title, btn } = this.props;

    return (
      <div className="row justify-content-center">
        <div className="col-lg-7 col-md-8">
          <div className="sec-heading center">
            {title || color_title ? (
              <h2 className="text-light">
                {title} <span className="theme-cl">{color_title}</span>
              </h2>
            ) : null}
            <p className="text-light">{description}</p>

            {btn}
          </div>
        </div>
      </div>
    );
  }
}

export default Section_header;
