import React from "react";
import { get_request, post_request } from "../../assets/js/utils/services";
import Listempty from "../../components/listempty";
import Loadindicator from "../../components/loadindicator";
import Modal from "../../components/modal";
import Small_btn from "../../components/small_btn";
import Add_staff_member from "./add_staff_member";
import Dashboard_breadcrumb from "./dashboard_breadcrumb";
import Staff_member from "../../components/staff_member";
import Add_class from "./add_class";
import Class from "../../components/class";

class Manage_classes extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount = async () => {
    let classes = await get_request("classes");

    if (!Array.isArray(classes)) classes = new Array();

    this.setState({ classes });
  };

  toggle_add_class = () => this.staff?.toggle();

  on_add = (fc_class) => {
    let { classes, class_in_edit } = this.state;

    if (class_in_edit)
      classes = classes.map((staff) =>
        staff._id === class_in_edit._id ? fc_class : staff
      );
    else classes = new Array(fc_class, ...classes);

    this.setState({
      classes,
      class_in_edit: null,
    });
  };

  edit = (fc_class) => {
    this.setState({ class_in_edit: fc_class }, this.toggle_add_class);
  };

  remove = async (fc_class_id) => {
    let { classes } = this.state;

    if (!window.confirm("Are you sure to remove class?")) return;

    classes = classes.filter((fc_class) => fc_class._id !== fc_class_id);
    this.setState({ classes });

    await post_request(`remove_class/${fc_class_id}`);
  };

  render() {
    let { classes, class_in_edit } = this.state;

    return (
      <div className="col-lg-9 col-md-9 col-sm-12">
        <Dashboard_breadcrumb
          crumb="manage classes"
          right_btn={
            <Small_btn title="Add Class" action={this.toggle_add_class} />
          }
        />
        <div className="row justify-content-center">
          {classes ? (
            classes.length ? (
              classes.map((fc_class) => (
                <Class
                  edit={() => this.edit(fc_class)}
                  remove={() => this.remove(fc_class._id)}
                  fc_class={fc_class}
                  admin
                  key={fc_class._id}
                />
              ))
            ) : (
              <Listempty />
            )
          ) : (
            <Loadindicator />
          )}
        </div>

        <Modal ref={(staff) => (this.staff = staff)}>
          <Add_class
            fc_class={class_in_edit}
            on_add={this.on_add}
            toggle={this.toggle_add_class}
          />
        </Modal>
      </div>
    );
  }
}

export default Manage_classes;
