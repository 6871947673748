import React from "react";
import { get_request, post_request } from "../../assets/js/utils/services";
import Listempty from "../../components/listempty";
import Loadindicator from "../../components/loadindicator";
import Modal from "../../components/modal";
import Small_btn from "../../components/small_btn";
import Add_staff_member from "./add_staff_member";
import Dashboard_breadcrumb from "./dashboard_breadcrumb";
import Staff_member from "../../components/staff_member";

class Manage_staff extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount = async () => {
    let staffs = await get_request("staff_members");

    if (!Array.isArray(staffs)) staffs = new Array();

    this.setState({ staffs });
  };

  toggle_add_staff = () => this.staff?.toggle();

  on_add = (member) => {
    let { staffs, member_in_edit } = this.state;

    if (member_in_edit)
      staffs = staffs.map((staff) =>
        staff._id === member_in_edit._id ? member : staff
      );
    else staffs = new Array(member, ...staffs);

    this.setState({
      staffs,
      member_in_edit: null,
    });
  };

  edit = (member) => {
    this.setState({ member_in_edit: member }, this.toggle_add_staff);
  };

  remove = async (member) => {
    let { staffs } = this.state;

    if (!window.confirm("Are you sure to remove staff member?")) return;

    staffs = staffs.filter((staff) => staff._id !== member._id);
    this.setState({ staffs });

    await post_request(`remove_staff_member/${member._id}`);
  };

  render() {
    let { staffs, member_in_edit } = this.state;

    return (
      <div className="col-lg-9 col-md-9 col-sm-12">
        <Dashboard_breadcrumb
          crumb="manage staff"
          right_btn={
            <Small_btn
              title="Add Staff Member"
              action={this.toggle_add_staff}
            />
          }
        />
        <div className="row justify-content-center">
          {staffs ? (
            staffs.length ? (
              staffs.map((member) => (
                <Staff_member
                  edit={this.edit}
                  remove={this.remove}
                  member={member}
                  key={member._id}
                />
              ))
            ) : (
              <Listempty />
            )
          ) : (
            <Loadindicator />
          )}
        </div>

        <Modal ref={(staff) => (this.staff = staff)}>
          <Add_staff_member
            member={member_in_edit}
            on_add={this.on_add}
            toggle={this.toggle_add_staff}
          />
        </Modal>
      </div>
    );
  }
}

export default Manage_staff;
