import React from "react";
import ReactMarkdown from "react-markdown";
import { H3, H2, H1, Img_tag, Li } from "./product_description";

class Class_details extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    let { fc_class } = this.props;
    let { things_to_know, what_to_expect, description } = fc_class;

    let desc = description;

    return (
      <>
        <div class="edu_wraper">
          <h4 class="edu_title text-light">Class Details</h4>
          {desc.split("\n").map((d, index) => (
            <ReactMarkdown
              key={index}
              // remarkPlugins={[remarkGfm]}
              children={d}
              className="text-light"
              style={{ color: "#fff" }}
              components={{
                img: Img_tag,
                h3: H3,
                h2: H2,
                h1: H1,
                li: Li,
              }}
            />
          ))}

          {things_to_know && things_to_know.length ? (
            <>
              <h6>Thing you need to know</h6>
              <ul class="simple-list p-0">
                {things_to_know.map((knw, index) => (
                  <li className="text-light" key={index}>
                    {knw}
                  </li>
                ))}
              </ul>
            </>
          ) : null}
        </div>

        {what_to_expect && what_to_expect.length ? (
          <div class="edu_wraper">
            <h4 class="edu_title">What to expect</h4>
            <ul class="lists-3 row">
              {what_to_expect.map((wah, index) => (
                <li
                  key={index}
                  class="col-xl-4 col-lg-6 col-md-6 m-0 text-light"
                >
                  {wah}
                </li>
              ))}
            </ul>
          </div>
        ) : null}
      </>
    );
  }
}

export default Class_details;
