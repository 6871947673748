import React from "react";
import { Link } from "react-router-dom";
import {
  commalise_figures,
  date_string,
  time_string,
} from "../assets/js/utils/functions";
import { save_to_session } from "../sections/footer";

class Transaction extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  tx_clicked = (e) => {
    e.preventDefault();
  };

  handle_click = (datatype, data, vendor) => {
    save_to_session(datatype, data);
    save_to_session("vendor", vendor);
  };

  handle_details = () => {
    let { transaction } = this.props;
    let { subscription: sb } = transaction;

    if (sb && typeof sb === "object")
      this.setState({ datum: sb }, this.details.toggle);
  };

  render() {
    let { datum } = this.state;
    let { transaction } = this.props;

    let { title, data, subscription, vendor, credit, value, created } =
      transaction;

    this.data_ = data;

    this.data_ = new Array();

    let routename = "";

    return (
      <div
        className="col-md-5 col-lg-5 col-xl-3 col-sm-12"
        style={{
          borderBottom: "1px solid #eee",
          boxShadow: "5px 5px 20px #eee",
          margin: 20,
          boxSizing: "border-box",
          borderRadius: 10,
        }}
      >
        <div class="ground ground-list-single">
          <div
            class={`rounded-circle p-3 p-sm-4 d-flex align-items-center justify-content-center bg-light-${
              credit ? "success" : "warning"
            }`}
          >
            <div
              class={`position-absolute text-${
                credit ? "success" : "warning"
              } h5 mb-0`}
            >
              <i class={`fas fa-arrow-${credit ? "down" : "up"}`}></i>
            </div>
          </div>

          <div class="ground-content">
            <Link to={`/class/${data?.class}`}>
              <h6>
                {datum || subscription ? (
                  <a href="#" onClick={this.handle_details}>
                    {title}
                  </a>
                ) : (
                  <span>{title}</span>
                )}
              </h6>
            </Link>
            {data && data._id ? (
              <>
                <Link
                  onClick={() => this.handle_click(routename, data, vendor)}
                  to={`/${routename}`}
                >
                  <h5 style={{ marginBottom: 0 }}>{data.title}</h5>
                </Link>
              </>
            ) : null}

            <b>
              <small class="text-fade">
                <>&pound;</> {commalise_figures(value)}
              </small>
            </b>

            <br />

            <span class="small">
              {time_string(created)}, {date_string(created)}
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default Transaction;
