import React from "react";
import Custom_nav from "../sections/nav";
import Padder from "../components/padder";
import Breadcrumb_banner from "../sections/breadcrumb_banner";
import Contact_us from "../components/contact_us_today";
import Footer from "../sections/footer";
import Stretch_button from "../components/stretch_button";
import { get_request, post_request } from "../assets/js/utils/services";
import { parse_query } from "../assets/js/utils/functions";
import Loadindicator from "../components/loadindicator";

class Checkout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount = async () => {
    let { cart } = parse_query();
    if (!cart) return window.history.go(-1);

    cart = await post_request(`cart/${cart}`);

    if (!cart?.length) return window.history.go(-1);

    this.setState({ cart });
  };

  calc_total = () => {
    let { cart } = this.state;

    let total = 0;

    cart.map((item) => {
      total += item.quantity * Number(item.product.value);
    });
    return Number(total).toFixed(2);
  };

  proceed = async () => {
    let {
      cart,
      loading,
      apartment,
      landline,
      addition_information,
      street,
      phone,
      state,
      city,
      email,
      fullname,
      postcode,
    } = this.state;
    if (loading) return;

    this.setState({ loading: true });

    let res = await post_request("checkout", {
      user: cart[0].user,
      apartment,
      landline,
      addition_information,
      street,
      phone,
      state,
      city,
      email,
      fullname,
      postcode,
    });

    window.location.assign(res.url);
  };

  render() {
    let {
      street,
      phone,
      postcode,
      loading,
      state,
      city,
      fullname,
      email,
      cart,
    } = this.state;

    return (
      <div>
        <Custom_nav page="checkout" />
        <Padder />

        <Breadcrumb_banner page="Billing Page" />

        <section className="gray">
          {!cart ? (
            <Loadindicator />
          ) : (
            <div className="container">
              <div className="row frm_submit_block">
                <div className="col-lg-8 col-md-12 col-sm-12">
                  <div className="submit-page mb-4">
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <h3 className="ml-0 text-light">Billing Detail</h3>
                      </div>

                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                          <label className="text-light">
                            Name<i className="req">*</i>
                          </label>
                          <input
                            type="text"
                            onChange={({ target }) =>
                              this.setState({ fullname: target.value })
                            }
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                          <label className="text-light">
                            Email<i className="req">*</i>
                          </label>
                          <input
                            type="text"
                            onChange={({ target }) =>
                              this.setState({ email: target.value })
                            }
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="form-group">
                          <label className="text-light">
                            Street<i className="req">*</i>
                          </label>
                          <input
                            onChange={({ target }) =>
                              this.setState({ street: target.value })
                            }
                            type="text"
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                          <label className="text-light">Apartment</label>
                          <input
                            onChange={({ target }) =>
                              this.setState({ apartment: target.value })
                            }
                            type="text"
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                          <label className="text-light">
                            Town/City<i className="req">*</i>
                          </label>
                          <input
                            onChange={({ target }) =>
                              this.setState({ city: target.value })
                            }
                            type="text"
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                          <label className="text-light">
                            State<i className="req">*</i>
                          </label>
                          <input
                            onChange={({ target }) =>
                              this.setState({ state: target.value })
                            }
                            type="text"
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                          <label className="text-light">
                            Postcode/Zip<i className="req">*</i>
                          </label>
                          <input
                            onChange={({ target }) =>
                              this.setState({ postcode: target.value })
                            }
                            type="text"
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                          <label className="text-light">
                            Phone<i className="req">*</i>
                          </label>
                          <input
                            type="text"
                            onChange={({ target }) =>
                              this.setState({ phone: target.value })
                            }
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                          <label className="text-light">Landline</label>
                          <input
                            onChange={({ target }) =>
                              this.setState({ landline: target.value })
                            }
                            type="text"
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="form-group">
                          <label className="text-light">
                            Additional Information
                          </label>
                          <textarea
                            onChange={({ target }) =>
                              this.setState({
                                addition_information: target.value,
                              })
                            }
                            className="form-control ht-50"
                          ></textarea>
                        </div>
                      </div>

                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <Stretch_button
                          title={`Pay ${this.calc_total()} EUR`}
                          action={this.proceed}
                          disabled={
                            loading ||
                            !state ||
                            !street ||
                            !phone ||
                            !city ||
                            !fullname ||
                            !email ||
                            !postcode
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <h3 className="text-light">Your Order</h3>
                  </div>

                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="pro_product_wrap">
                      <h5>Products</h5>
                      <ul>
                        {cart.map((item) => (
                          <li key={item._id}>
                            <strong>{item.product.title}</strong>
                            {item.quantity} x &euro;
                            {item.product.value}
                          </li>
                        ))}
                        <li>
                          <strong>Total</strong>{" "}
                          <b>&euro;{this.calc_total()}</b>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </section>

        <Contact_us />
        <Footer />
      </div>
    );
  }
}

export default Checkout;
