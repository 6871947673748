import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/styles.css";
import "./assets/css/custom.css";
import { Loggeduser, Logged_admin, Nav_context } from "./Contexts";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Emitter from "semitter";
// Pages
import Home from "./pages/Home";
import Login from "./pages/Login";
import Forgot_password from "./pages/Forgot_password";
import Signup from "./pages/Signup";
import Page_not_found from "./pages/404";
import Adminstrator from "./pages/Adminstrator";
import {
  client_domain,
  club_domain,
  shop_domain,
} from "./assets/js/utils/constants";
import { get_request } from "./assets/js/utils/services";
import { get_session } from "./sections/footer";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Verify_email from "./pages/Verify_email";
import Product from "./pages/Product";
import Testimonials from "./pages/Testimonials";
import Class from "./pages/Class";
import Book from "./pages/Book";
import Cart from "./pages/Cart";
import Article from "./pages/Article";
import Blog from "./pages/Blog";
import Profile from "./pages/Profile";
import Donation_received from "./pages/Donation_received";
import Checkout from "./pages/Checkout";
import Purchase_successful from "./pages/Purchase_successful";

const emitter = new Emitter();

class FCTouch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      navs: new Array(
        {
          title: "home",
          path: client_domain,
        },
        {
          title: "club",
          path: club_domain,
          submenu: new Array(
            {
              title: `membership - ${new Date().getFullYear()}/${
                new Date().getFullYear() + 1
              } `,
              path: `${club_domain}/membership`,
            },
            {
              title: "sponsors",
              path: `${club_domain}/sponsors`,
            },
            {
              title: "volunteers",
              path: `${club_domain}/volunteers`,
            },
            {
              title: "gallery",
              path: `${club_domain}/gallery`,
            }
          ),
        },
        {
          title: "shop",
          path: shop_domain,
          submenu: new Array({
            title: "cart",
            path: `${client_domain}/cart`,
          }),
        },
        {
          title: "about",
          path: "/about",
        },
        {
          title: "reviews",
          path: "/testimonials",
        },
        {
          title: "blog",
          path: "/blog",
        },
        {
          title: "get_started",
          path: "/signup?p=club",
        }
      ),
    };
  }

  componentDidMount = async () => {
    if (
      !new Array("profile", "adminstrator", "").includes(
        window.location.href.split("/").slice(-1)[0]
      )
    )
      return window.location.assign(client_domain);

    let loggeduser = get_session("loggeduser");
    loggeduser && this.setState({ loggeduser });

    emitter.single_listener("is_logged_in", this.is_logged_in);

    let entry = await get_request("entry");
    this.setState({ entry });
  };

  componentWillUnmount = () => {};

  logout = () =>
    this.setState({ loggeduser: null }, () => {
      window.sessionStorage.removeItem("loggeduser");
      window.location.assign(client_domain);

      delete this.log_timestamp;
    });

  restore_loggeduser = (loggeduser, cb) =>
    this.setState({ loggeduser }, () => {
      window.sessionStorage.setItem("loggeduser", JSON.stringify(loggeduser));
      cb && cb();
    });

  login = (user, no_redirect) =>
    this.setState({ loggeduser: user }, () => {
      window.sessionStorage.setItem("loggeduser", JSON.stringify(user));

      if (!this.log_timestamp) this.log_timestamp = Date.now();

      if (no_redirect) return;

      let red = get_session("redirect");
      if (red === shop_domain) red = `${red}?u=${user._id}`;

      window.sessionStorage.removeItem("redirect");
      window.location.assign(red || client_domain);
    });

  log_admin = (admin) =>
    this.setState({ admin_logged: admin }, () => {
      window.sessionStorage.setItem("logged_admin", JSON.stringify(admin));
    });

  render = () => {
    let { loggeduser, entry, navs, subnavs, submenus, admin_logged } =
      this.state;

    return (
      <Loggeduser.Provider
        value={{
          loggeduser,
          logout: this.logout,
          set_loggeduser: this.restore_loggeduser,
          login: this.login,
          is_logged_in: this.is_logged_in,
        }}
      >
        <Logged_admin.Provider
          value={{ admin_logged, log_admin: this.log_admin }}
        >
          <Nav_context.Provider
            value={{
              navs,
              subnavs,
              set_subnav: this.set_subnav,
              load_subnavs: this.load_subnavs,
              submenus,
              logo: entry?.logo,
            }}
          >
            <BrowserRouter>
              <Routes>
                <Route index element={<Home entry={entry} />} />
                <Route path="about" element={<About entry={entry} />} />
                <Route path="contact" element={<Contact />} />
                <Route path="login" element={<Login />} />
                <Route path="signup" element={<Signup />} />
                <Route path="profile" element={<Profile />} />
                <Route path="checkout" element={<Checkout />} />
                <Route path="class/:class_id" element={<Class />} />
                <Route path="product" element={<Product />} />
                <Route path="article" element={<Article />} />
                <Route path="blog" element={<Blog />} />
                <Route
                  path="purchase_successful"
                  element={<Purchase_successful />}
                />
                <Route
                  path="donation_received"
                  element={<Donation_received />}
                />
                <Route path="book/:class_id" element={<Book />} />
                <Route path="cart" element={<Cart />} />
                <Route path="testimonials" element={<Testimonials />} />
                <Route path="verify_email" element={<Verify_email />} />
                <Route path="forgot_password" element={<Forgot_password />} />
                <Route path="adminstrator" element={<Adminstrator />} />
                <Route path="*" element={<Page_not_found />} />
              </Routes>
            </BrowserRouter>
          </Nav_context.Provider>
        </Logged_admin.Provider>
      </Loggeduser.Provider>
    );
  };
}

export default FCTouch;
export { emitter };
