import React from "react";
import { get_request, post_request } from "../../assets/js/utils/services";
import Listempty from "../../components/listempty";
import Loadindicator from "../../components/loadindicator";
import Modal from "../../components/modal";
import Small_btn from "../../components/small_btn";
import Add_category from "./add_category";
import Dashboard_breadcrumb from "./dashboard_breadcrumb";
import Category from "../../components/category";

class Manage_categories extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount = async () => {
    let categories = await get_request("product_categories");

    if (!Array.isArray(categories)) categories = new Array();

    this.setState({ categories });
  };

  toggle_add_category = () => this.category?.toggle();

  on_add = (category) => {
    let { categories, category_in_edit } = this.state;

    if (category_in_edit)
      categories = categories.map((cat) =>
        cat._id === category_in_edit._id ? category : cat
      );
    else categories = new Array(category, ...categories);

    this.setState({
      categories,
      category_in_edit: null,
    });
  };

  edit = (category) => {
    this.setState({ category_in_edit: category }, this.toggle_add_category);
  };

  remove = async (category) => {
    let { categories } = this.state;

    if (!window.confirm("Are you sure to remove category?")) return;

    categories = categories.filter((cat) => cat._id !== category._id);
    this.setState({ categories });

    await post_request(`remove_category/${category._id}`);
  };

  render() {
    let { categories, category_in_edit } = this.state;

    return (
      <div className="col-lg-9 col-md-9 col-sm-12">
        <Dashboard_breadcrumb
          crumb="manage categories"
          right_btn={
            <Small_btn title="Add Category" action={this.toggle_add_category} />
          }
        />
        <div className="row justify-content-center">
          {categories ? (
            categories.length ? (
              categories.map((category) => (
                <Category
                  edit={this.edit}
                  remove={this.remove}
                  category={category}
                  key={category._id}
                />
              ))
            ) : (
              <Listempty />
            )
          ) : (
            <Loadindicator />
          )}
        </div>

        <Modal ref={(category) => (this.category = category)}>
          <Add_category
            category={category_in_edit}
            on_add={this.on_add}
            toggle={this.toggle_add_category}
          />
        </Modal>
      </div>
    );
  }
}

export default Manage_categories;
