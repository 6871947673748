import React from "react";
import Modal_form_title from "./modal_form_title";
import Booking from "./booking";
import Listempty from "./listempty";
import Loadindicator from "./loadindicator";
import { post_request } from "../assets/js/utils/services";

class Class_bookings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount = async () => {
    let { fc_class } = this.props;

    let bookings = await post_request(`class_bookings/${fc_class._id}`);

    console.log(bookings);
    this.setState({ bookings });
  };

  render() {
    let { toggle } = this.props;
    let { bookings } = this.state;

    return (
      <div>
        <div className="modal-content overli" id="loginmodal">
          <Modal_form_title title="Bookings" toggle={toggle} />

          <div className="modal-body">
            <div className="login-form">
              <div className="row">
                {bookings ? (
                  bookings.length ? (
                    bookings.map((book) => (
                      <Booking booking={book} key={book._id} />
                    ))
                  ) : (
                    <div
                      className="my-5"
                      style={{ textAlign: "center", width: "100%" }}
                    >
                      <p style={{ textAlign: "center" }} className="h4">
                        {"Nothing here yet."}
                      </p>
                    </div>
                  )
                ) : (
                  <Loadindicator />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Class_bookings;
