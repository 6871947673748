import React from "react";
import Preview_image from "./preview_image";
import { commalise_figures, date_string } from "../assets/js/utils/functions";
import { post_request } from "../assets/js/utils/services";
import { client_domain } from "../assets/js/utils/constants";
import { Link } from "react-router-dom";

class Cart_item extends React.Component {
  constructor(props) {
    super(props);

    let { item } = this.props;

    this.state = { quantity: item.quantity || 1 };
  }

  remove_item = async () => {
    if (!window.confirm("Are you sure to remove product from cart?")) return;

    let { item, on_remove } = this.props;

    await post_request("remove_from_cart", {
      user: item.user,
      product: item.product._id,
    });

    on_remove && on_remove(item._id);
  };

  update_quantity = async (inc) => {
    let { item } = this.props;
    let { quantity } = this.state;

    if (quantity === 1 && !inc) return;

    if (inc) quantity++;
    else quantity--;

    this.setState({ quantity });

    await post_request("update_cart_quantity", {
      cart: item._id,
      quantity,
      user: item.user,
    });
  };

  render() {
    let { quantity } = this.state;
    let { item } = this.props;
    let { product, created } = item;

    return (
      <tr>
        <td>
          <a
            onClick={(e) => {
              e.preventDefault();

              this.remove_item();
            }}
            href="#"
            class="remove_cart"
          >
            <i class="ti-close"></i>
          </a>
        </td>
        <td>
          <div class="">
            <Preview_image
              action={() =>
                window.location.assign(
                  `${client_domain}/product?${product._id}`
                )
              }
              image={product.images[0].url}
              image_hash={product.images[0].image_hash}
              class_name="img-fluid rounded cursor-pointer"
              height={75}
            />
          </div>
        </td>
        <th>
          <Link to={`/product?${product._id}`}>
            {product.title}
            <span class="tb_date">{date_string(created)}</span>
          </Link>
        </th>
        <td>
          <span class="wish_price theme-cl">
            &pound; {commalise_figures(product.value * quantity)}
          </span>
        </td>

        <td>
          <a
            href="#"
            onClick={() => this.update_quantity()}
            class="btn theme-bg text-white"
          >
            <i class="fa fa-chevron-left"></i>
          </a>
          <span className="mx-3">{quantity}</span>
          <a
            href="#"
            onClick={() => this.update_quantity(true)}
            class="btn theme-bg text-white"
          >
            <i class="fa fa-chevron-right"></i>
          </a>
        </td>
      </tr>
    );
  }
}

export default Cart_item;
