import React from "react";
import { Link } from "react-router-dom";
import { commalise_figures } from "../assets/js/utils/functions";
import { save_to_session, scroll_to_top } from "../sections/footer";
import Preview_image from "./preview_image";
import Modal from "./modal";
import Class_bookings from "./class_bookings";

class Class extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handle_vendor = () => {
    let { vendor } = this.props;

    save_to_session("vendor", vendor);
  };

  toggle_bookings = () => this.bookings?.toggle();

  render() {
    let { state: state_ } = this.state;
    let { fc_class, full, no_foot, in_classes, edit, remove, no_btn, admin } =
      this.props;
    let {
      title,
      image,
      image_file_hash,
      bookings,
      subscription_price,
      price,
      _id,
    } = fc_class;

    if (state_) state = state_;

    return (
      <div
        className={
          full
            ? "col-11"
            : in_classes
            ? "col-xl-6 col-lg-6 col-md-6 col-sm-12"
            : "col-xl-4 col-lg-4 col-md-6 col-sm-12"
        }
      >
        <div className="crs_grid">
          <div className="crs_grid_thumb">
            <Link
              to={`/class/${_id}`}
              onClick={() => {
                save_to_session("fc_class", {
                  ...fc_class,
                });
                scroll_to_top();
              }}
              className="crs_detail_link"
            >
              <Preview_image
                image={image || require("../assets/img/vouchers1.png")}
                image_hash={image_file_hash}
                class_name="w-100 img-fluid rounded"
              />
            </Link>

            {remove ? (
              <div
                className="crs_video_ico cursor-pointer"
                style={{}}
                onClick={() => remove()}
              >
                <i className={`fa fa-trash`}></i>
              </div>
            ) : null}
            {edit ? (
              <div className="crs_locked_ico cursor-pointer" onClick={edit}>
                <i className={`fa fa-edit`}></i>
              </div>
            ) : null}
          </div>
          <div className="crs_grid_caption">
            <div className="crs_flex">
              {/* <div className="crs_fl_first">
                <div className="crs_cates cl_8">
                  <span>{""}</span>
                </div>
              </div> */}
              {bookings ? (
                <div className="crs_fl_last">
                  <div className="crs_inrolled">
                    <strong>{commalise_figures(bookings, true)}</strong>
                    Bookings
                  </div>
                </div>
              ) : null}
            </div>
            <div className="crs_title">
              <h4>
                <Link
                  to={`/class/${_id}`}
                  onClick={() => {
                    save_to_session("fc_class", {
                      ...fc_class,
                    });
                    scroll_to_top();
                  }}
                  className="crs_title_link"
                >
                  <b>{title}</b>
                </Link>
              </h4>
            </div>
          </div>
          {no_foot ? null : (
            <div className="crs_grid_foot">
              <div className="crs_flex">
                <div className="crs_fl_first">
                  <div className="crs_price mr-3">
                    <h4>
                      {price || subscription_price ? (
                        <>
                          <span className="currency">&pound;</span>
                          <span className="theme-cl">
                            {commalise_figures(
                              Number(price || subscription_price)
                            )}
                          </span>
                        </>
                      ) : (
                        <span className="currency theme-cl">Free</span>
                      )}
                    </h4>
                  </div>
                </div>
                {no_btn ? null : (
                  <div className="crs_fl_last">
                    <div className="crs_linkview mr-4">
                      <Link
                        to={admin ? "" : `/book/${_id}`}
                        onClick={
                          admin
                            ? () => this.toggle_bookings()
                            : () => save_to_session("book", fc_class)
                        }
                      >
                        <span className="btn btn_view_detail theme-bg text-light">
                          {admin ? "Bookings" : "Book Now"}
                        </span>
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        <Modal ref={(bookings) => (this.bookings = bookings)}>
          <Class_bookings fc_class={fc_class} toggle={this.toggle_bookings} />
        </Modal>
      </div>
    );
  }
}

export default Class;
