import React from "react";
import Loadindicator from "../components/loadindicator";
import Section_header from "../components/section_headers";
import Small_btn from "../components/small_btn";
import { club_domain } from "../assets/js/utils/constants";
import { get_request } from "../assets/js/utils/services";
import { date_string } from "../assets/js/utils/functions";

class Membership extends React.Component {
  constructor(props) {
    super(props);

    this.state = { membership: "loading" };
  }

  componentDidMount = async () => {
    let { user } = this.props;

    if (user.annual_sub > Date.now())
      this.setState({
        membership: await get_request(`membership/${user.membership}`),
      });
    else this.setState({ membership: null });
  };

  render() {
    let { user } = this.props;
    let { membership } = this.state;

    if (membership === "loading") return <Loadindicator />;

    return membership ? (
      <div className="row justify-content-center">
        <div className="col-xl-6 col-lg-8 col-md-8 col-sm-12">
          <div class="single_instructor">
            <div class="single_instructor_caption">
              <h4>
                <a href="#">{membership.fullname}</a>
              </h4>
              <ul class="instructor_info">
                <li>
                  <i class="ti-calendar"></i>Date of Birth:&nbsp;
                  <b>{date_string(new Date(membership.dob))}</b>
                </li>
                <li>
                  <i class="ti-control-forward"></i>
                  Age: <b>{membership.age}</b>
                </li>
                <li>
                  <i class="ti-user"></i>Valid till.{" "}
                  <b>
                    {date_string(
                      membership.created + 60 * 60 * 1000 * 24 * 365
                    )}
                  </b>
                </li>
              </ul>
              <p>{membership.address}</p>
              <span style={{ textDecoration: "underline" }}>
                Parent / Guardian Contact
              </span>
              <h6>
                Name: <b>{membership.parent_name}</b>
              </h6>
              <h6>
                Contact Number: <b>{membership.contact_number}</b>
              </h6>
              <h6>
                Emergency Contact Number:{" "}
                <b>{membership.emergency_contact_number}</b>
              </h6>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <Section_header
        btn={
          <Small_btn
            title="Become a member"
            action={() =>
              window.location.assign(`${club_domain}/membership?u=${user?._id}`)
            }
          />
        }
        title="Join our"
        color_title="Club"
      />
    );
  }
}

export default Membership;
