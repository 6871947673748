import React from "react";
import { domain } from "../assets/js/utils/constants";
import { post_request } from "../assets/js/utils/services";
import Alert_box from "./alert_box";
import Form_divider from "./form_divider";
import Modal_form_title from "./modal_form_title";
import Text_input from "./text_input";
import { date_string } from "../assets/js/utils/functions";

class Booking_details extends React.Component {
  constructor(props) {
    super(props);

    let { booking } = this.props;

    this.state = {
      suspended: booking.suspended,
    };
  }

  url = (file) => window.open(`${domain}/files/${file}`);

  verify = async () => {
    let { loading } = this.state;
    if (loading) return;

    this.setState({ loading: true });
    let { booking, toggle, on_verify } = this.props;

    let res = await post_request(`verify_vendor/${booking._id}`);

    if (res && res.message)
      return this.setState({ message: res.message, loading: false });

    if (res.verified) on_verify && on_verify(booking._id);
    else toggle && toggle();
  };

  suspend = async () => {
    if (!window.confirm("Are you sure to suspend booking?")) return;

    let { booking } = this.props;

    await post_request(`suspend_vendor/${booking._id}`);

    this.setState({ suspended: true });
  };

  remove_suspension = async () => {
    if (!window.confirm("Are you sure to remove booking from suspension?"))
      return;

    let { booking } = this.props;

    await post_request(`remove_suspension/${booking._id}`);

    this.setState({ suspended: false });
  };

  delete_account = async () => {
    window.alert("Coming soon...");
  };

  render() {
    let { loading, message, suspended } = this.state;
    let { booking, toggle } = this.props;

    let { booking: book } = booking;

    let {
      fullname,
      firstname,
      permit_photograph,
      lastname,
      date_of_birth,
      subscription,
      email,
      phone,
      foot,
      gender,
      allergies,
      created,
    } = book;

    return (
      <div style={{ overflow: "scroll" }}>
        <form>
          <div className="crs_log_wrap">
            <div className="crs_log__caption">
              <div
                style={{
                  display: "flex",
                  marginTop: 10,
                  marginBottom: 10,
                  justifyContent: "center",
                }}
              >
                <span style={{ marginRight: 10 }}>Date Created: </span>{" "}
                <h5>{date_string(created)}</h5>
              </div>

              <Modal_form_title title="Child Information" toggle={toggle} />

              <div className="row">
                <Form_divider text="brand information" />
                <Text_input
                  value={firstname}
                  class_name="col-6"
                  disabled
                  title="Firstname"
                />

                <Text_input
                  value={lastname}
                  class_name="col-6"
                  disabled
                  title="Lastname"
                />

                <Text_input
                  value={date_string(date_of_birth)}
                  disabled
                  title="Date of Birth"
                />

                <Text_input value={gender} disabled title="Gender" />

                <Form_divider text="Parent / Guardian" />

                <Text_input value={fullname} disabled title="Fullname" />
                <Text_input value={email} disabled title="Email" />
                <Text_input value={phone} disabled title="Phone" />

                <Form_divider text="More Details" />
                <Text_input value={foot} disabled title="Foot" />
                <Text_input value={allergies} disabled title="Allergies" />
                {permit_photograph ? (
                  <Alert_box type="info" message={"Photograph Permitted"} />
                ) : null}
              </div>
              {message ? <Alert_box message={message} /> : null}

              <div style={{ marginBottom: 24 }} />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default Booking_details;
