import React from "react";
import { domain } from "../../assets/js/utils/constants";
import { get_request, post_request } from "../../assets/js/utils/services";
import Alert_box from "../../components/alert_box";
import Handle_file_upload from "../../components/handle_file_upload";
import Loadindicator from "../../components/loadindicator";
import Stretch_button from "../../components/stretch_button";
import Text_btn from "../../components/text_btn";
import Dashboard_breadcrumb from "./dashboard_breadcrumb";
import { commalise_figures, to_title } from "../../assets/js/utils/functions";
import Small_btn from "../../components/small_btn";

class Manage_membership_page extends Handle_file_upload {
  constructor(props) {
    super(props);

    this.state = {
      sections: new Array(),
      price_breakdown: new Array(),
      title: "Memberships",
      pricing_index: null,
      pricing_in_edit: new Object(),
    };
  }

  componentDidMount = async () => {
    let memberships = await get_request("memberships_page");

    this.setState({ ...memberships, memberships });
  };

  set_section = (text, index) => {
    let { sections } = this.state;
    sections[index].text = text;

    this.setState({ sections });
  };

  add_section = (type) => {
    let { sections } = this.state;
    sections.push({ type, text: "" });
    this.setState({ sections });
  };

  remove_section = (index) => {
    let { sections } = this.state;
    sections.splice(index, 1);

    this.setState({ sections });
  };

  submit = async () => {
    let {
      sections,
      image,
      image_file_hash,
      price_breakdown,
      title,
      fee,
      memberships,
    } = this.state;
    if (!memberships) return;

    this.setState({ updating: true, message: null });

    memberships = {
      sections,
      price_breakdown,
      image,
      fee: Number(fee),
      image_file_hash,
      title,
    };

    let res = await post_request("update_memberships", memberships);

    this.setState({
      updating: false,
      image: res?.image,
      message: res?.message,
    });
  };

  handle_pricing = (e) => {
    e?.preventDefault();
    let { pricing_in_edit, pricing_index, price_breakdown } = this.state;

    if (!pricing_in_edit?.service_name) return;

    if (pricing_index !== null) {
      price_breakdown[pricing_index] = pricing_in_edit;
      pricing_index = null;
    } else price_breakdown = new Array(...price_breakdown, pricing_in_edit);

    this.setState({
      price_breakdown,
      pricing_index,
      pricing_in_edit: {},
    });
  };

  edit_pricing = (index) => {
    let pricing_in_edit = this.state.price_breakdown[index];
    this.setState({ pricing_in_edit, pricing_index: index });
  };

  filter_pricing_index = (index) => {
    let { price_breakdown } = this.state;
    price_breakdown.splice(index, 1);
    this.setState({ price_breakdown });
  };

  handle_price = ({ target }) => this.setState({ price: target.value });

  render() {
    let {
      updating,
      image,
      title,
      price_breakdown,
      image_file_loading,
      message,
      sections,
      fee,
    } = this.state;

    return (
      <div className="col-12">
        <Dashboard_breadcrumb crumb="Membership" />
        <div className="row justify-content-center">
          <div className="col-md-8 col-lg-8 col-xl-6 col-sm-12">
            <div className="crs_grid">
              <div className="modal-header">
                <h5 className="modal-title text-dark">Membership</h5>
              </div>

              <div className="modal-body">
                <div className="login-form">
                  <form>
                    <div className="row">
                      <div className="col-12">
                        <div className="form-group">
                          <label>Page Title</label>
                          <input
                            className="form-control"
                            placeholder="Page Title"
                            value={title}
                            onChange={({ target }) =>
                              this.setState({ title: target.value })
                            }
                          />
                        </div>
                      </div>

                      {sections.map((section, index) => (
                        <div key={index} className="form-group">
                          <span>
                            <label>{`Paragraph - (${index + 1})`}</label>
                            <a
                              onClick={() => this.remove_section(index)}
                              className="btn btn-action"
                            >
                              <i className={`fas fa-window-close`}></i>
                            </a>
                          </span>
                          <textarea
                            onChange={({ target }) =>
                              this.set_section(target.value, index)
                            }
                            value={section.text}
                            className="form-control"
                          ></textarea>
                        </div>
                      ))}

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Text_btn
                          text="Add Section"
                          icon="fa-plus"
                          action={(e) => {
                            e.preventDefault();
                            this.add_section("text");
                          }}
                        />
                      </div>

                      <div className="form-group smalls">
                        <label>Page Image*</label>
                        <div className="custom-file">
                          <input
                            type="file"
                            className="custom-file-input"
                            id="customFile"
                            accept="image/*"
                            onChange={(e) =>
                              this.handle_file(e, "image", null, null, true)
                            }
                          />
                          <label className="custom-file-label" for="customFile">
                            Choose Image
                          </label>
                        </div>
                        {image_file_loading ? (
                          <Loadindicator />
                        ) : (
                          <div style={{ overflow: "scroll" }}>
                            <span>
                              <img
                                className="py-3 rounded"
                                style={{
                                  maxHeight: 200,
                                  maxWidth: 200,
                                  marginRight: 10,
                                }}
                                src={
                                  image && image.startsWith("data")
                                    ? image
                                    : `${domain}/images/${image}`
                                }
                              />
                            </span>
                          </div>
                        )}
                      </div>

                      <div className="form-group smalls">
                        <label>Annual Membership Fee(&euro;)</label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter Consultation Price"
                          value={fee || ""}
                          onChange={({ target }) =>
                            this.setState({
                              fee: target.value,
                            })
                          }
                        />
                      </div>

                      <div className="row">
                        <div className="form-group smalls col-6">
                          <label>Sevice Name*</label>
                          <input
                            className="form-control"
                            placeholder="Enter Consultation Price"
                            value={
                              this.state.pricing_in_edit.service_name || ""
                            }
                            onChange={({ target }) =>
                              this.setState({
                                pricing_in_edit: {
                                  ...this.state.price_in_edit,
                                  service_name: target.value,
                                },
                              })
                            }
                          />
                        </div>

                        <div className="form-group smalls col-6">
                          <label>Price(&euro;)</label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Enter Consultation Price"
                            value={this.state.pricing_in_edit.price || ""}
                            onChange={({ target }) =>
                              this.setState({
                                pricing_in_edit: {
                                  ...this.state.pricing_in_edit,
                                  price: target.value,
                                },
                              })
                            }
                          />
                        </div>
                        <div className="col-6">
                          <Small_btn
                            title={
                              this.state.pricing_index !== null ? "Edit" : "Add"
                            }
                            action={this.handle_pricing}
                          />
                        </div>
                      </div>

                      <br />
                      <div className="form-group">
                        <label>Price Breakdown*</label>

                        <table className="table dash_list">
                          <tr>
                            <th>Service Name</th>
                            <th>Price</th>
                            <th>Action</th>
                          </tr>
                          {price_breakdown.map((breakdown, index) => {
                            return (
                              <tr style={{ cursor: "pointer" }} key={index}>
                                <td>
                                  <h6>{to_title(breakdown.service_name)}</h6>
                                </td>
                                {Number(breakdown.price) > 0 ? (
                                  <td>
                                    &euro;{commalise_figures(breakdown.price)}
                                  </td>
                                ) : (
                                  <td>
                                    <span>Free</span>
                                  </td>
                                )}
                                <td>
                                  <div className="dhs_tags">
                                    <Text_btn
                                      text="edit"
                                      action={() => this.edit_pricing(index)}
                                    />
                                    &nbsp;&nbsp;&nbsp;
                                    <Text_btn
                                      text="remove"
                                      action={() =>
                                        this.filter_pricing_index(index)
                                      }
                                    />
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </table>
                      </div>
                    </div>

                    {message ? <Alert_box message={message} /> : null}

                    <Stretch_button
                      loading={updating}
                      title="Update"
                      action={this.submit}
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Manage_membership_page;
