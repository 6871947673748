import React from "react";
import { Link } from "react-router-dom";
import { commalise_figures } from "../assets/js/utils/functions";
import { post_request } from "../assets/js/utils/services";
import { Loggeduser } from "../Contexts";
import { save_to_session } from "../sections/footer";
import Login from "./login";
import Modal from "./modal";
import Preview_image from "./preview_image";
import { client_domain } from "../assets/js/utils/constants";

class Product extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  componentDidMount = async () => {
    let { product } = this.props;
    if (this.loggeduser) {
      let in_cart = await post_request("in_cart", {
        user: this.loggeduser._id,
        product: product._id,
      });

      this.setState({
        in_cart,
      });
    }
  };

  manage_cart = async (user) => {
    let { product } = this.props;
    let { in_cart, carting } = this.state;
    if (carting) return;
    this.setState({ carting: true });

    if (in_cart) {
      return window.location.assign(`${client_domain}/cart`);
    }
    if (!this.loggeduser && !user) {
      this.login?.toggle();
    } else {
      await post_request("add_to_cart", {
        user: (this.loggeduser || user)._id,
        product: product._id,
      });
      this.setState({ in_cart: true });
    }
    this.setState({ carting: false });
  };

  save_to_wishlist = async (loggeduser) => {
    if (!loggeduser) return this.login?.setState({ show: true });

    this.login.setState({ show: false });
    let { product } = this.props;
    let { wishlisted, wishing } = this.state;
    if (wishing) return;

    this.setState({ wishing: true });

    await post_request(
      wishlisted ? "remove_from_wishlist" : "add_to_wishlist",
      {
        product: product._id,
        user: loggeduser._id,
      }
    );
    return this.setState({ wishlisted: !wishlisted, wishing: false });
  };

  toggle_product_subscribers = () => this.product_subscribers?.toggle();

  handle_product = () => save_to_session("product", this.props.product);

  render() {
    let { in_cart, carting } = this.state;
    let { class_name, product, remove, edit } = this.props;

    let { title, images, value, _id } = product;

    return (
      <Loggeduser.Consumer>
        {({ loggeduser }) => {
          this.loggeduser = loggeduser;

          return (
            <div
              className={class_name || "col-xl-3 col-lg-4 col-md-6 col-sm-12"}
            >
              <div className="prd_grid_box">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    paddingTop: 10,
                    paddingRight: 10,
                  }}
                >
                  {remove ? (
                    <div
                      className="prt_saveed_12lk pr-3"
                      onClick={() => remove()}
                    >
                      <label className="toggler toggler-danger">
                        <input type="checkbox" />
                        <i className="fas fa-trash"></i>
                      </label>
                    </div>
                  ) : null}

                  {edit ? (
                    <div
                      className="prt_saveed_12lk pr-3"
                      onClick={() => edit()}
                    >
                      <label className="toggler toggler-danger">
                        <input type="checkbox" />
                        <i className="fas fa-edit"></i>
                      </label>
                    </div>
                  ) : null}

                  {!edit && !remove ? (
                    <div
                      className="prt_saveed_12lk"
                      onClick={() => this.save_to_wishlist(loggeduser)}
                    >
                      <label className="toggler toggler-danger">
                        <input type="checkbox" />
                        <i className="fas fa-heart"></i>
                      </label>
                    </div>
                  ) : null}
                </div>
                <div className="prd_grid_thumb">
                  <Link to={`/product?${_id}`} onClick={this.handle_product}>
                    <Preview_image
                      image={images[0].url}
                      image_hash={images[0].image_hash}
                    />
                  </Link>
                </div>
                <div className="prd_grid_caption">
                  <div className="prd_center_capt">
                    <Link to={`/product?${_id}`} onClick={this.handle_product}>
                      <div className="prd_title">
                        <h4>{title}</h4>
                      </div>
                    </Link>
                    <div className="prd_price_info">
                      <h5 className="org_prc">
                        <span className="old_prc"></span>&pound;
                        {commalise_figures(value)}
                      </h5>
                    </div>
                  </div>
                  <div className="prd_bot_capt">
                    <div
                      className={`prd_button ${
                        class_name === "col-11" ? "mr-4" : ""
                      }`}
                    >
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          this.manage_cart();
                        }}
                        className="bth bth_prd"
                      >
                        {in_cart ? "View in Cart" : "Add to Cart"}
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <Modal ref={(login) => (this.login = login)}>
                <Login
                  no_redirect
                  toggle={() => this.login.setState({ show: false })}
                  action={carting ? this.manage_cart : this.save_to_wishlist}
                />
              </Modal>
            </div>
          );
        }}
      </Loggeduser.Consumer>
    );
  }
}

export default Product;
