import React from "react";
import {
  date_string,
  time_string,
  to_title,
} from "../assets/js/utils/functions";
import { Link } from "react-router-dom";
import { save_to_session } from "../sections/footer";

class Class_sidebar extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = async () => {};

  parse_datetime = (datetime) => {
    let date = new Date(datetime).getTime();

    return `${date_string(date)}, ${time_string(date)}`;
  };

  render() {
    let { fc_class } = this.props;
    let {
      bookings,
      _id,
      subscription_duration,
      subscription_benefits,
      subscription_price,
      duration,
    } = fc_class;

    return (
      <div className="col-lg-4 col-md-12 order-lg-last">
        <div className="ed_view_box style_2 border min pt-3">
          <span className="ml-3">Book Class</span>

          <div className="ed_view_link">
            <Link
              to={`/book/${_id}`}
              onClick={() => save_to_session("book", fc_class)}
              class="btn theme-light enroll-btn"
            >
              Book Now
              <i className="ti-angle-right"></i>
            </Link>
            <div style={{ textAlign: "center", paddingTop: 10 }}>
              <b>per {to_title(duration)}</b>
            </div>

            <hr />
            {subscription_price ? (
              <>
                (
                <div style={{ textAlign: "center" }}>
                  <p className="text-center mt-2">Or</p>
                  <h6>Get a discount by Subscribing</h6>

                  <b className="text-center">
                    &pound;
                    {`${subscription_price} / ${subscription_duration} days`}
                  </b>
                </div>
                ){" "}
                <Link
                  to={`/book/${_id}`}
                  onClick={() => save_to_session("book", fc_class)}
                  class="btn theme-bg enroll-btn"
                >
                  Subscribe
                  <i className="ti-angle-right"></i>
                </Link>
              </>
            ) : null}

            {Number(subscription_price) > 0 ? (
              <>
                {subscription_benefits?.length ? (
                  <div className="pt-4">
                    <h4>Subscription Benefits</h4>
                    <ul class="simple-list p-0">
                      {subscription_benefits.map((wah, index) => (
                        <li key={index} class="col-12 m-0">
                          {wah}
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : null}
              </>
            ) : null}
          </div>

          <div className="ed_view_features">
            <div className="eld mb-3">
              <ul className="edu_list right">
                <li>
                  <i className="ti-time"></i>
                  {"Total Bookings"}:<strong>{bookings}</strong>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Class_sidebar;
