import React from "react";
import { post_request } from "../assets/js/utils/services";
import Loadindicator from "../components/loadindicator";
import { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import Product from "../components/product";
import Explore_more_btn from "../components/explore_more_btn";
import { shop_domain } from "../assets/js/utils/constants";

class Featured_products extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount = async () => {
    let products = await post_request("products", {
      limit: 12,
      shuffle: true,
    });

    this.setState({ products });
  };

  render() {
    let { products } = this.state;
    if (products && !products.length) return;

    return (
      <section className="gray" id="shop">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7 col-md-8">
              <div className="sec-heading center">
                <h2 className="text-light">
                  our <span className="theme-cl">Products</span>
                </h2>
                <p className="text-light">
                  Explore, manage subscription and trade product listings with
                  best price and plan
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            {products ? (
              <Swiper
                modules={[Autoplay, Pagination]}
                pagination={{ clickable: true }}
                slidesPerView={window.innerWidth < 650 ? 1 : 3}
                autoplay={{
                  delay: 2000,
                  pauseOnMouseEnter: true,
                  disableOnInteraction: false,
                }}
                loop
                className="swiper-container"
              >
                {products.map((product) => (
                  <SwiperSlide key={product._id}>
                    <Product product={product} class_name="col-11" />
                  </SwiperSlide>
                ))}
              </Swiper>
            ) : (
              <Loadindicator />
            )}
          </div>

          {products && products.length ? (
            <Explore_more_btn href={`${shop_domain}`} title="Products" />
          ) : null}
        </div>
      </section>
    );
  }
}

export default Featured_products;
