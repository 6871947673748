import React from "react";

class Booking_successful extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    let { details } = this.props;
    let { fullname, email } = details;

    return (
      <div
        style={{
          textAlign: "center",
          boxShadow: `rgba(0, 0, 0, 0.3) 5px 5px 12px`,
          borderRadius: 20,
          padding: 20,
          marginLeft: 20,
          marginRight: 20,
          marginBottom: 20,
        }}
      >
        <p>
          Hello,{" "}
          <span style={{ textTransform: "capitalize", fontWeight: "bold" }}>
            {fullname}
          </span>
        </p>
        <h4> Booking Successful</h4>

        <p>
          Details on your transaction has been sent to your email at{" "}
          <b>
            <em>{email}</em>
          </b>
        </p>

        <p>Please do ensure to keep it safe!</p>
      </div>
    );
  }
}

export default Booking_successful;
