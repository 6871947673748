import React from "react";
import Contact_us from "../components/contact_us_today";
import Breadcrumb_banner from "../sections/breadcrumb_banner";
import Padder from "../components/padder";
import Custom_nav from "../sections/nav";
import Footer, { scroll_to_top } from "../sections/footer";
import Staff_members from "../sections/staff_members";
import Who_we_are from "../sections/who_we_are";
import { get_request } from "../assets/js/utils/services";
import Testimonials from "../sections/testimonials";
import { organisation_name } from "../assets/js/utils/constants";
import Loadindicator from "../components/loadindicator";
import Donations from "../sections/donations";
import Vision_mission_stuff from "../sections/vision_mission_stuff";
import In_progress from "./In_progress";

class About extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount = async () => {
    scroll_to_top();
    document.title = `About | ${organisation_name}`;

    let about_statement = await get_request("about_statement");

    this.setState({ about_statement });
  };

  render() {
    let { entry } = this.props;
    let { about_statement } = this.state;

    return <In_progress />;
    return (
      <div id="main-wrapper">
        <Custom_nav page="about" />
        <Padder />

        <Breadcrumb_banner page="About Us" title="Who are we?" />
        <section>
          {about_statement ? (
            <Who_we_are about={about_statement} />
          ) : (
            <Loadindicator />
          )}

          <Staff_members />

          <Vision_mission_stuff reverted details={entry?.vision} />

          <Testimonials />
          <Donations />
        </section>

        <Contact_us />

        <Footer />
      </div>
    );
  }
}

export default About;
