import React from "react";
import { Link } from "react-router-dom";
import Padder from "../components/padder";
import Footer from "../sections/footer";
import Nav from "../sections/nav";
import { club_domain, shop_domain } from "../assets/js/utils/constants";

class In_progress extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount = () => {
    if (window.location.pathname === "/shop")
      window.location.assign(shop_domain);
    else if (window.location.pathname === "/club")
      window.location.assign(club_domain);
  };

  paths = new Array("shop", "club");
  render() {
    if (this.paths.includes(window.location.pathname.slice(1))) return;

    return (
      <div className="main-wrapper">
        <Nav page="404" />
        <Padder />
        <section class="error-wrap">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-6 col-md-10">
                <div class="text-center">
                  <img
                    src={require("../assets/img/404.png")}
                    class="img-fluid"
                    alt=""
                  />
                  <p className="text-white">
                    "Site is still under development"
                  </p>
                  <Link
                    class="btn theme-bg text-white btn-md"
                    to="https://club.fcfirsttouch.com"
                  >
                    Back To Club
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

export default In_progress;
