import React from "react";
import Booking_details from "./booking_details";
import Modal from "./modal";

class Booking extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  toggle_booking_details = () => this.booking?.toggle();

  render() {
    let { booking, full } = this.props;
    let { booking: book } = booking;
    let { fullname, email } = book;

    return (
      <div
        onClick={this.toggle_booking_details}
        className={full ? "col-12" : "col-lg-6 col-md-6 col-sm-12"}
      >
        <div className="edu_cat_2 cat-1">
          <div>
            <span style={{ width: "100%", textAlign: "center" }}>
              <div className="edu_cat_data pt-2 text-center">
                <b>Details</b>
                <h4 className="title">
                  <a href="#">{`${fullname}`}</a>
                </h4>
                <ul className="meta">
                  <li className="video">{email}</li>
                </ul>
              </div>
            </span>
          </div>
        </div>

        <Modal ref={(booking) => (this.booking = booking)}>
          <Booking_details
            booking={booking}
            toggle={this.toggle_booking_details}
          />
        </Modal>
      </div>
    );
  }
}

export default Booking;
