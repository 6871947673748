import React from "react";

class Hero_banner extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount = () => {};

  render() {
    let { hero } = this.props;
    let { title, sub_text, image, overlay } = hero;

    return (
      <div
        className="hero_banner image-cover image_bottom"
        style={{
          height: "75vh",
          width: "100%",
          zIndex: 99,
        }}
        data-overlay="8"
      >
        <div className="container">
          <div className="row align-items-center mx-auto">
            <div
              className="align-items-center mx-auto"
              style={{ textAlign: "center" }}
            >
              <h1
                className="banner_title mb-4 text-center px-2"
                style={{
                  backgroundColor: "rgba(0,0,0,0.0)",
                  display: "inline-block",
                }}
              >
                {title}
              </h1>
              <p
                className="font-lg mx-auto text-center mb-4"
                style={{
                  width: "60%",
                  fontSize: 20,
                  backgroundColor: "rgba(0,0,0,0.0)",
                }}
              >
                {sub_text}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Hero_banner;
