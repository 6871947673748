import React from "react";
import { get_request } from "../assets/js/utils/services";
import Listempty from "../components/listempty";
import Loadindicator from "../components/loadindicator";
import { Loggeduser } from "../Contexts";
import { get_session } from "../sections/footer";
import Class from "./class";
import User_session_header from "./user_session_header";

const voucher_tabs = new Array("open classes", "offer classes");

class User_sessions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      active_tab: voucher_tabs[0],
      filter: "running",
    };
  }

  componentDidMount = async () => {
    this.loggeduser = this.loggeduser || get_session("loggeduser");
    if (!this.loggeduser) return window.history.go(-1);

    let classes = await get_request(`user_bookings/${this.loggeduser._id}`);

    this.setState({ classes });
  };

  session_states = new Array("running", "past", "pending");

  render() {
    let { style } = this.props;
    let { classes, filter } = this.state;

    return (
      <Loggeduser.Consumer>
        {({ loggeduser }) => {
          this.loggeduser = loggeduser;
          return (
            <section className="min" style={{ ...style }}>
              <div className="">
                <>
                  <User_session_header
                    filters={this.session_states}
                    set_filter={(filter) => this.setState({ filter })}
                    session_type="Sessions"
                  />

                  <div className="row justify-content-center">
                    <div className="col-lg-7 col-md-8">
                      <div className="sec-heading center">
                        <p className="text-white">Your sessions booked.</p>
                      </div>
                    </div>
                  </div>

                  <div className="row justify-content-center">
                    {classes ? (
                      classes.length ? (
                        classes.map((fc_class, index) => {
                          if (!fc_class.state) fc_class.state = "running";

                          return fc_class.state === filter ? (
                            <Class
                              no_foot
                              fc_class={{
                                ...fc_class.class,
                              }}
                              key={index}
                            />
                          ) : null;
                        })
                      ) : (
                        <Listempty />
                      )
                    ) : (
                      <Loadindicator />
                    )}
                  </div>
                </>
              </div>
            </section>
          );
        }}
      </Loggeduser.Consumer>
    );
  }
}

export default User_sessions;
