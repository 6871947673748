import React from "react";
import { to_title } from "../assets/js/utils/functions";
import Text_btn from "./text_btn";

class Category extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    let { category, edit, remove } = this.props;

    if (!category) return;

    let { title, products } = category;

    return (
      <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
        <div class="crs_trt_grid">
          <div class="crs_trt_caption">
            <div class="instructor_tag dark">
              <span>{products || 0} Products</span>
            </div>
            <div class="instructor_title">
              <h4>
                <a>{to_title(title)}</a>
              </h4>
            </div>
          </div>

          {edit || remove ? (
            <div class="crs_trt_footer">
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {edit && <Text_btn text="Edit" action={() => edit(category)} />}
                {remove && (
                  <Text_btn text="Remove" action={() => remove(category)} />
                )}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default Category;
