import React from "react";
import { Col } from "react-bootstrap";
import { domain, get_request } from "../assets/js/utils/services";
import Video from "./video";

class Testimonials_header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount = async () => {
    let testimonials = await get_request("alumni_overview");

    this.setState({ testimonials });
  };

  render() {
    let { testimonials } = this.state;
    let { title, text, video, thumbnail, thumbnail_hash } =
      testimonials || new Object();

    return (
      <div className="row mb-3">
        <div
          className={`col-lg-${testimonials ? "6" : "12"} col-md-${
            testimonials ? "6" : "12"
          } ${
            testimonials ? "" : "justify-content-center"
          } col-sm-12 align-items-center d-flex`}
        >
          <div className="">
            <h2 className="text-light">
              {title || "Discover the Voices of Our Valued Community"}
            </h2>
            <p className="lead text-light">{text || ``}</p>
            <br />
            <br />
          </div>
        </div>
        {testimonials ? (
          <Col lg={6} md={6} sm={12} className="align-items-center">
            <Video
              url={`${domain}/videos/${video}`}
              thumbnail={thumbnail}
              thumbnail_hash={thumbnail_hash}
            />
          </Col>
        ) : null}
      </div>
    );
  }
}

export default Testimonials_header;
