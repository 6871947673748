import React from "react";
import ReactMarkdown from "react-markdown";

const Img_tag = ({ src }) => {
  return (
    <img
      src={src}
      className="img-fluid rounded"
      style={{
        width: "100%",
      }}
    />
  );
};

const H3 = ({ children }) => <h3 className="text-light">{children}</h3>;
const Li = ({ children }) => <li className="text-light">{children}</li>;
const H2 = ({ children }) => <h2 className="text-light">{children}</h2>;
const H1 = ({ children }) => <h1 className="text-light">{children}</h1>;

class Product_description extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    let { product } = this.props;
    let { description, what_to_expect } = product;

    return (
      <div className="edu_wraper">
        <h4 className="edu_title text-light">Description</h4>
        {description.split("\n").map((d, i) => (
          <ReactMarkdown
            key={i}
            // remarkPlugins={[remarkGfm]}
            children={d}
            className="text-light"
            style={{ color: "#fff" }}
            components={{
              img: Img_tag,
              h3: H3,
              h2: H2,
              h1: H1,
            }}
          />
        ))}
        {what_to_expect?.length ? (
          <div className="edu_wraper">
            <h4 className="edu_title text-light">What to expect</h4>
            <ul className="lists-3 row">
              {what_to_expect.map((wah, index) => (
                <li
                  key={index}
                  className="text-light col-xl-4 col-lg-6 col-md-6 m-0"
                >
                  {wah}
                </li>
              ))}
            </ul>
          </div>
        ) : null}
      </div>
    );
  }
}

export default Product_description;
export { Img_tag, H2, H3, H1, Li };
