import React from "react";

const Listempty = ({ text, style }) => {
  return (
    <div className="my-5" style={{ textAlign: "center", width: "100%" }}>
      <p style={{ textAlign: "center", ...style }} className="h4 text-light">
        {text || "Nothing here yet."}
      </p>
    </div>
  );
};

export default Listempty;
