import React from "react";
import Loadindicator from "../../components/loadindicator";
import { emitter } from "../../Fctouch";
import Dashboard_breadcrumb from "./dashboard_breadcrumb";
import Product from "../../components/product";
import { post_request } from "../../assets/js/utils/services";
import { scroll_to_top } from "../footer";

class Manage_products extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 0,
      page_size: 9,
      total_products: "-",
    };
  }

  fetch_products = async (page = this.state.page) => {
    let { page_size } = this.state;

    let res = await post_request("products", {
      total_products: true,
      limit: page_size,
      skip: page_size * (page || 0),
    });
    let { total_products, products } = res;

    let i = 0;
    for (let p = 0; p < total_products; p += page_size) i++;

    this.setState({
      products,
      total_products,
      page,
      total_pages: i,
    });
  };

  componentDidMount = async () => {
    await this.fetch_products();
  };

  filter_products = async () => {
    let { section, master_product, search_param } = this.state;

    let filter = new Object();
    if (section) filter.section = section;
    if (master_product) filter.master_product = master_product;
    if (search_param) filter.search_param = search_param;

    let { total_products, products } = await post_request("products", {
      filter,
      total_products: true,
      limit: this.page_size,
    });

    this.setState({ products, total_products });
  };

  page = async (page) => {
    await this.fetch_products(page);

    scroll_to_top();
  };

  next_page = async () => {
    let { page, total_pages } = this.state;
    page < total_pages && (await this.fetch_products(page + 1));
  };

  prev_page = async () => {
    let { page } = this.state;
    page > 0 && (await this.fetch_products(page - 1));
  };

  render_pagers = () => {
    let { page_size, page, total_products } = this.state,
      mapper = new Array(),
      i = 0;
    for (let p = 0; p < total_products; p += page_size) mapper.push(i++);

    return mapper.map((pager, index) => (
      <li
        className={`page-item ${index === page ? "active" : ""}`}
        onClick={() => this.page(index)}
      >
        <a className="page-link" href="#">
          {pager + 1}
        </a>
      </li>
    ));
  };

  search_product = ({ target }) =>
    this.setState({ search_param: target.value });

  render_pagination = () => {
    let { page, page_size, total_pages, products, total_products } = this.state;

    return (
      <div className="row align-items-center justify-content-between">
        <div className="col-xl-6 col-lg-6 col-md-12">
          <p className="p-0">{`Showing ${page * page_size + 1} to ${
            page * page_size + products.length
          } of ${total_products} entire`}</p>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-12">
          <nav className="float-right">
            <ul className="pagination smalls m-0">
              <li
                onClick={this.prev_page}
                className={`page-item ${page === 0 ? "disabled" : ""}`}
              >
                <a className="page-link" href="#" tabindex="-1">
                  <i className="fas fa-arrow-circle-left"></i>
                </a>
              </li>

              {this.render_pagers()}

              <li
                className={`page-item ${
                  total_pages === page ? "disabled" : ""
                }`}
                onClick={this.next_page}
              >
                <a className="page-link" href="#">
                  <i className="fas fa-arrow-circle-right"></i>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    );
  };

  edit_product = (product) => emitter.emit("edit_product", product);

  delete_product = async (product_id) => {
    if (!window.confirm("Are you sure to remove product?")) return;

    let { products, total_products } = this.state;
    products = products.filter((product) => product._id !== product_id);
    total_products--;
    this.setState({ products, total_products });
    await post_request(`remove_product/${product_id}`);
  };

  render() {
    let { filter, products } = this.state;

    return (
      <div className="col-lg-9 col-md-9 col-sm-12">
        <Dashboard_breadcrumb crumb="manage product" />

        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12">
            <div className="dashboard_wrap">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 mb-4">
                  <h6 className="m-0">All products List</h6>
                </div>
              </div>
              <div className="row align-items-end mb-5">
                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6">
                  <div className="form-group smalls row align-items-center">
                    <label className="col-xl-2 col-lg-2 col-sm-2 col-form-label">
                      Search
                    </label>
                    <div className="col-xl-10 col-lg-10 col-sm-10">
                      <input
                        onChange={this.search_product}
                        type="text"
                        placeholder="product title..."
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <hr />
                {products && products.map ? (
                  products.map((product) => (
                    <Product
                      adminstrator
                      product={product}
                      key={product._id}
                      remove={() => {
                        this.delete_product(product._id);
                      }}
                      edit={() => this.edit_product(product)}
                    />
                  ))
                ) : (
                  <Loadindicator contained />
                )}
              </div>
              {/* Pagination */}
              {products ? this.render_pagination() : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Manage_products;
