import React from "react";

class Custom_details extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    let { product } = this.props;

    return (
      <div className="edu_wraper">
        <h4 className="edu_title text-light">Purchase</h4>
        <div className="table-responsive"></div>
      </div>
    );
  }
}

export default Custom_details;
