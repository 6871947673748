import React from "react";
import { client_domain } from "../assets/js/utils/constants";
import { get_request, post_request } from "../assets/js/utils/services";
import Listempty from "../components/listempty";
import Loadindicator from "../components/loadindicator";
import Padder from "../components/padder";
import { Loggeduser } from "../Contexts";
import Footer, {
  get_session,
  save_to_session,
  scroll_to_top,
} from "../sections/footer";
import Custom_nav from "../sections/nav";
import Breadcrumb_banner from "../sections/breadcrumb_banner";
import Contact_us from "../components/contact_us_today";
import Cart_item from "../components/cart_item";
import Stretch_button from "../components/stretch_button";
import { Link } from "react-router-dom";

class Cart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  page_size = 25;

  componentDidMount = async () => {
    scroll_to_top();

    if (!this.loggeduser) this.loggeduser = get_session("loggeduser");

    if (!this.loggeduser) {
      let u = window.location.search;
      u = u?.split("=")[1];

      if (u && u.startsWith("users~"))
        this.loggeduser = await get_request(`user/${u}`);
    }

    if (!this.loggeduser) {
      save_to_session("redirect", "cart");
      return window.location.assign(`${client_domain}/login`);
    }

    let cart = await post_request(`cart/${this.loggeduser._id}`);

    this.setState({ cart });
  };

  on_remove = (item_id) => {
    let { cart } = this.state;

    cart = cart.filter((item) => item._id !== item_id);
    this.setState({ cart });
  };

  render() {
    let { cart } = this.state;

    return (
      <Loggeduser.Consumer>
        {({ loggeduser }) => {
          this.loggeduser = loggeduser;

          return (
            <div>
              <Custom_nav page="cart" />
              <Padder />

              <Breadcrumb_banner page="cart" />

              <section className="gray">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-xl-10 col-lg-12 col-md-12 col-sm-12">
                      <div className="table-responsive">
                        <table className="table table-striped wishlist">
                          <tbody>
                            {cart ? (
                              cart.length ? (
                                cart.map((product) => (
                                  <Cart_item
                                    on_remove={this.on_remove}
                                    item={product}
                                    key={product._id}
                                  />
                                ))
                              ) : (
                                <Listempty />
                              )
                            ) : (
                              <Loadindicator />
                            )}
                          </tbody>
                        </table>
                      </div>
                      <br />
                      {cart && cart.length ? (
                        <Link to={`/checkout?cart=${cart[0].user}`}>
                          <span>
                            <Stretch_button title="Proceed to Checkout" />
                          </span>
                        </Link>
                      ) : null}
                    </div>
                  </div>
                </div>
              </section>

              <Contact_us />
              <Footer />
            </div>
          );
        }}
      </Loggeduser.Consumer>
    );
  }
}

export default Cart;
