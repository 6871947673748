import React from "react";
import { organisation_name } from "../assets/js/utils/constants";
import { to_title } from "../assets/js/utils/functions";
import { get_request } from "../assets/js/utils/services";
import Loadindicator from "../components/loadindicator";
import Padder from "../components/padder";
import Footer, { get_session, scroll_to_top } from "../sections/footer";
import Custom_Nav from "../sections/nav";
import Class_overview from "../components/class_overview";
import Class_sidebar from "../components/class_sidebar";

class Class extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount = async () => {
    scroll_to_top();
    let fc_class = get_session("fc_class");

    if (!fc_class) {
      let href = window.location.href.split("/").slice(-2);

      let details = await get_request(`get_class/${href[1]}`);

      fc_class = details;
    }

    if (fc_class)
      document.title = `${to_title(fc_class.title)} | ${organisation_name}`;
    else return window.history.go(-1);

    this.setState({ fc_class });
  };

  on_transfer = () => this.setState({ transferred: true });

  on_redeem = () => this.setState({ redeemed: true });

  render() {
    let { fc_class } = this.state;
    if (!fc_class) return <Loadindicator />;

    return (
      <div>
        <Custom_Nav page="fc_class" />
        <Padder />

        {/* <Voucher_header fc_class={fc_class}  /> */}

        <section class="gray pt-5">
          <div class="container">
            <div class="row">
              <Class_overview fc_class={fc_class} />

              <Class_sidebar fc_class={fc_class} />
            </div>
          </div>
        </section>

        <Footer />
      </div>
    );
  }
}

export default Class;
