import React from "react";
import Class_media from "./class_media";
import Class_details from "./class_details";
import Class_reviews from "./class_reviews";

class Class_overview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    let { fc_class, vendor, event } = this.props;
    if (!fc_class) fc_class = event;

    return (
      <div class="col-lg-8 col-md-12 order-lg-first">
        <Class_media fc_class={fc_class} />

        <Class_details fc_class={fc_class} />

        <Class_reviews fc_class={fc_class} />
      </div>
    );
  }
}

export default Class_overview;
