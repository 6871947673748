import React from "react";
import { Link } from "react-router-dom";
import Padder from "../components/padder";
import Footer from "../sections/footer";
import Nav from "../sections/nav";
import { club_domain, shop_domain } from "../assets/js/utils/constants";

class Purchase_successful extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  paths = new Array("shop", "club");
  render() {
    if (this.paths.includes(window.location.pathname.slice(1))) return;

    return (
      <div className="main-wrapper">
        <Nav page="404" />
        <Padder />
        <section class="error-wrap">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-6 col-md-10">
                <div class="text-center">
                  <img
                    src={require("../assets/img/donations.png")}
                    class="img-fluid"
                    alt=""
                  />
                  <h2 className="text-light my-3">Payment Received</h2>
                  <p className="text-light">
                    Thank you for your generous donation to our football club.
                    Your support is more than just financial; it's a commitment
                    to the growth, development, and success of our players and
                    our community.
                  </p>
                  <Link class="btn theme-bg text-white btn-md" to="/">
                    Back To Home
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

export default Purchase_successful;
