import React from "react";
import { domain, post_request } from "../../assets/js/utils/services";
import Handle_file_upload from "../../components/handle_file_upload";
import Loadindicator from "../../components/loadindicator";
import Modal_form_title from "../../components/modal_form_title";
import Stretch_button from "../../components/stretch_button";
import Form_divider from "../../components/form_divider";
import { to_title } from "../../assets/js/utils/functions";

const per_sessions = new Array("session", "hour");

class Add_class extends Handle_file_upload {
  constructor(props) {
    super(props);

    let { fc_class } = this.props;

    this.state = {
      title: "",
      description: "",
      subscription_benefits: new Array(),
      duration: per_sessions[0],
      ...fc_class,
      subscription_benefit_index: null,
    };
  }

  add = async () => {
    let { toggle, on_add } = this.props;
    let {
      title,
      image,
      image_file_hash,
      subscription_price,
      subscription_benefits,
      duration,
      subscription_duration,
      price,
      description,
      _id,
    } = this.state;
    this.setState({ loading: true });

    let cat = {
      title: title.trim(),
      _id,
      image,
      image_file_hash,
      price,
      subscription_price,
      subscription_benefits,
      duration,
      subscription_duration,
      description,
      price,
    };

    let result = await post_request(_id ? "update_class" : "add_class", cat);

    if (result && result._id) {
      cat._id = result._id;
      cat.image = result.image;
      cat.created = result.created;

      on_add(cat);
      toggle();
    } else {
      this.setState({
        message:
          (result && result.message) || "Cannot create class at the moment.",
        loading: false,
      });
    }
  };

  filter_subscription_benefit = (index) => {
    let { subscription_benefits } = this.state;
    subscription_benefits.splice(index, 1);
    this.setState({ subscription_benefits });
  };

  edit_subscription_benefit = (index) => {
    let subscription_benefits_in_edit = this.state.subscription_benefits[index];
    this.setState({
      subscription_benefits_in_edit,
      subscription_benefit_index: index,
    });
  };

  add_subscription_benefit = (e) => {
    e.preventDefault();
    let {
      subscription_benefits_in_edit,
      subscription_benefit_index,
      subscription_benefits,
    } = this.state;

    if (subscription_benefit_index !== null) {
      subscription_benefits[subscription_benefit_index] =
        subscription_benefits_in_edit;
      subscription_benefit_index = null;
    } else
      subscription_benefits = new Array(
        ...subscription_benefits,
        subscription_benefits_in_edit
      );

    this.setState({
      subscription_benefits,
      subscription_benefit_index,
      subscription_benefits_in_edit: "",
    });
  };

  render() {
    let { toggle } = this.props;
    let {
      title,
      loading,
      price,
      subscription_duration,
      subscription_price,
      description,
      _id,
      image,
      image_file_loading,
      subscription_benefits_in_edit,
      subscription_benefits,
      subscription_benefit_index,
    } = this.state;

    return (
      <div>
        <div className="modal-content overli" id="loginmodal">
          <Modal_form_title title="Class Details" toggle={toggle} />

          <div className="modal-body">
            <div className="login-form">
              <form>
                <div className="form-group smalls">
                  <label>Class Banner*</label>
                  <div className="custom-file">
                    <input
                      type="file"
                      className="custom-file-input"
                      id="customFile"
                      accept="image/*"
                      onChange={(e) =>
                        this.handle_file(e, "image", null, null, true)
                      }
                    />
                    <label className="custom-file-label" for="customFile">
                      Choose Image
                    </label>
                  </div>
                  {image_file_loading ? (
                    <Loadindicator />
                  ) : (
                    <div
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <span>
                        <img
                          className="py-3 rounded"
                          style={{
                            maxHeight: 200,
                            maxWidth: 200,
                            marginRight: 10,
                          }}
                          src={
                            image && image.startsWith("data")
                              ? image
                              : `${domain}/images/${image}`
                          }
                        />
                      </span>
                    </div>
                  )}
                </div>

                <div className="form-group">
                  <label>Title</label>
                  <div className="input-with-icon">
                    <input
                      type="text"
                      className="form-control"
                      value={title}
                      onChange={({ target }) =>
                        this.setState({
                          title: target.value,
                          message: "",
                        })
                      }
                      placeholder="Title"
                    />
                    <i className="ti-text"></i>
                  </div>
                </div>

                <div className="form-group">
                  <label>Description</label>
                  <div className="">
                    <textarea
                      type={"text"}
                      className="form-control"
                      placeholder="Details..."
                      value={description}
                      onChange={({ target }) =>
                        this.setState({
                          description: target.value,
                          message: "",
                        })
                      }
                    ></textarea>
                  </div>
                </div>

                <Form_divider text="Guest" />

                <div className="row">
                  <div className="form-group col-6">
                    <label>Price (&pound;)</label>
                    <div className="">
                      <input
                        type={"number"}
                        min="0"
                        className="form-control"
                        placeholder="0.00"
                        value={price}
                        onChange={({ target }) =>
                          this.setState({
                            price: target.value,
                            message: "",
                          })
                        }
                      />
                    </div>
                  </div>

                  <div className="simple-input col-6">
                    <label>Duration</label>
                    <select
                      id="per_session"
                      onChange={(e) =>
                        this.setState({ duration: e.target.value })
                      }
                      className="form-control"
                    >
                      {per_sessions.map((per_session) => (
                        <option key={per_session} value={per_session}>
                          {to_title(per_session.replace(/_/g, " "))}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <Form_divider text="Subscription" />

                <div className="row">
                  <div className="form-group col-6">
                    <label>
                      Price (&pound;) - <b>NB:with subscription</b>
                    </label>
                    <div className="">
                      <input
                        type={"number"}
                        min="0"
                        className="form-control"
                        placeholder="0.00"
                        value={subscription_price}
                        onChange={({ target }) =>
                          this.setState({
                            subscription_price: target.value,
                            message: "",
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="form-group col-6">
                    <label>Duration</label>
                    <div className="">
                      <input
                        type={"number"}
                        min="0"
                        className="form-control"
                        placeholder="0.00"
                        value={subscription_duration}
                        onChange={({ target }) =>
                          this.setState({
                            subscription_duration: target.value,
                            message: "",
                          })
                        }
                      />
                    </div>
                  </div>
                </div>

                {Number(subscription_price) > 0 ? (
                  <>
                    {" "}
                    <div className="form-group smalls">
                      <label>Add Subscription Benefit</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Type subscription benefit"
                        value={subscription_benefits_in_edit}
                        onChange={({ target }) =>
                          this.setState({
                            subscription_benefits_in_edit: target.value,
                          })
                        }
                      />
                      {subscription_benefits_in_edit ? (
                        <a
                          onClick={this.add_subscription_benefit}
                          href="#"
                          className="btn theme-bg text-light mt-2"
                        >
                          {subscription_benefit_index === null
                            ? "Add"
                            : "Update"}
                        </a>
                      ) : null}
                    </div>
                    {subscription_benefits.length ? (
                      <ul className="simple-list p-0">
                        {subscription_benefits.map(
                          (subscription_benefit, i) => (
                            <li key={i}>
                              {subscription_benefit}{" "}
                              <span
                                className="px-2"
                                onClick={() =>
                                  this.filter_subscription_benefit(i)
                                }
                              >
                                <i className={`fa fa-trash`}></i>
                              </span>
                              <span
                                className="px-2"
                                onClick={() =>
                                  this.edit_subscription_benefit(i)
                                }
                              >
                                <i className={`fa fa-edit`}></i>
                              </span>
                            </li>
                          )
                        )}
                      </ul>
                    ) : null}
                  </>
                ) : null}

                <div className="form-group">
                  <Stretch_button
                    disabled={
                      !title.trim() ||
                      (!subscription_price && !price) ||
                      !image ||
                      !description.trim()
                    }
                    loading={loading}
                    title={_id ? "Update" : "Add"}
                    action={this.add}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Add_class;
