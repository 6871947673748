import React from "react";
import { Carousel } from "react-bootstrap";
import Contact_us from "../components/contact_us_today";
import Loadindicator from "../components/loadindicator";
import { Loggeduser } from "../Contexts";
import Footer from "../sections/footer";
import Hero_banner from "../sections/hero_banner";
import Nav from "../sections/nav";
import Testimonials from "../sections/testimonials";
import Featured_products from "../sections/featured_products";
import Staff_members from "../sections/staff_members";
import Classes from "../sections/classes";
import Articles from "../sections/articles";
import Donations from "../sections/donations";
import In_progress from "./In_progress";

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      index: 0,
      heros: new Array({
        main_text: "",
        sub_text: "",
        bg: require("../assets/img/hero1.jpg"),
      }),
    };
  }

  componentDidMount = () => {
    let search = window.location.search;

    if (search.endsWith("shop"))
      document.getElementById("shop").scrollIntoView();
    else if (search.endsWith("classes"))
      document.getElementById("classes").scrollIntoView();
    this.setState({ admin: search.endsWith("admin") });

    document.getElementById("my_video")?.play();
    document.getElementById("myVideo")?.play();
  };

  render() {
    let { heros, admin } = this.state;
    let { entry } = this.props;

    if (!admin) return <In_progress />;
    return (
      <Loggeduser.Consumer>
        {({ loggeduser }) => {
          return (
            <div>
              <Nav page="" />
              <div className="body">
                <video autoplay muted loop id="myVideo">
                  <source
                    src={require("../assets/vids/bg.mp4")}
                    type="video/mp4"
                  />
                </video>

                {entry || heros ? (
                  <div
                    style={{
                      backgroundImage: `url(${require("../assets/img/hero1.png")})`,
                    }}
                    data-overlay="9"
                  >
                    <video autoplay muted loop id="my_video">
                      <source
                        src={require("../assets/vids/banner.mp4")}
                        type="video/mp4"
                      />
                    </video>
                    <Carousel fade nextLabel="" prevLabel="" indicators={false}>
                      {(entry?.banners || heros).map((hero, index) => (
                        <Carousel.Item>
                          <Hero_banner hero={hero} key={index} />
                        </Carousel.Item>
                      ))}
                    </Carousel>
                  </div>
                ) : (
                  <Loadindicator />
                )}

                <Classes />

                {/* <Featured_products /> */}

                <Staff_members />
                <Testimonials />
                <Donations />

                <Articles />

                <Contact_us />
              </div>
              <Footer />
            </div>
          );
        }}
      </Loggeduser.Consumer>
    );
  }
}

export default Home;
