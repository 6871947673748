import React from "react";
import Contact_us_today from "../components/contact_us_today";
import Breadcrumb_banner from "../sections/breadcrumb_banner";
import { email_regex, to_title } from "../assets/js/utils/functions";
import Accordion from "react-bootstrap/Accordion";
import Class from "../components/class";
import Footer, { get_session, scroll_to_top } from "../sections/footer";
import Custom_nav from "../sections/nav";
import { get_request, post_request } from "../assets/js/utils/services";
import { organisation_name } from "../assets/js/utils/constants";
import Loadindicator from "../components/loadindicator";
import { Loggeduser } from "../Contexts";
import Padder from "../components/padder";
import Form_divider from "../components/form_divider";
import { Link } from "react-router-dom";
import Checkbox from "../components/checkbox";
import Booking_successful from "../components/booking_successful";
import Alert_box from "../components/alert_box";

let genders = new Array("male", "female", "rather not say");

class Book extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount = async () => {
    scroll_to_top();
    let fc_class = get_session("booking");

    if (!fc_class) {
      let href = window.location.href.split("/").slice(-2);

      let details = await get_request(`get_class/${href[1]}`);

      fc_class = details;
    }

    if (fc_class)
      document.title = `Book | ${to_title(
        fc_class.title
      )} | ${organisation_name}`;
    else return window.history.go(-1);

    this.setState({
      fc_class,
      ...this.loggeduser,
      fullname: this.loggeduser
        ? `${this.loggeduser.firstname} ${this.loggeduser.lastname}`
        : "",
    });
  };

  _is_set = () => {
    let {
      email,
      attest,
      firstname,
      fullname,
      date_of_birth,
      gender,
      loading,
      phone,
      lastname,
    } = this.state;

    return (
      email_regex.test(email) &&
      firstname?.trim() &&
      fullname?.trim() &&
      attest &&
      !loading &&
      phone?.trim() &&
      date_of_birth &&
      gender &&
      lastname?.trim()
    );
  };

  proceed = async (subscription) => {
    let {
      permit_photograph,
      allergies,
      date_of_birth,
      fullname,
      gender,
      lastname,
      firstname,
      phone,
      email,
      fc_class,
      foot,
    } = this.state;

    if (!this._is_set())
      return this.setState({ message: "Please fill out the form fields." });

    this.setState({ loading: true });

    let details = {
      permit_photograph,
      allergies,
      date_of_birth,
      fullname,
      value: subscription ? fc_class.subscription_price : fc_class.price,
      gender,
      user: this.loggeduser?._id,
      lastname,
      firstname,
      class: fc_class._id,
      phone,
      email: email?.toLowerCase(),
      subscription,
      foot,
    };

    let res = await post_request("new_booking", details);
    if (res?.url) window.location.assign(res.url);
    else
      this.setState({
        loading: false,
        message: res?.message || "Cannot place booking at the moment",
      });
  };

  item = (faq, index) => {
    let { title, body, _id } = faq;

    return (
      <Accordion.Item
        eventKey={`${index}`}
        key={_id}
        style={{ backgroundColor: "#111" }}
      >
        <Accordion.Header style={{ backgroundColor: "#111" }}>
          <h6 class="mb-0 accordion_title">
            <a
              href="#"
              data-toggle="collapse"
              style={{
                textTransform: "none",
                color: "#000",
              }}
            >
              {title}
            </a>
          </h6>
        </Accordion.Header>
        <Accordion.Body style={{ backgroundColor: "#111" }}>
          <div
            data-parent="#accordionExample"
            style={{ backgroundColor: "#111" }}
          >
            <div class="card-body pl-3 pr-3 pt-0">{body}</div>
          </div>
        </Accordion.Body>
      </Accordion.Item>
    );
  };

  render() {
    let {
      email,
      fullname,
      firstname,
      lastname,
      date_of_birth,
      attest,
      phone,
      message,
      loading,
      booked,
      fc_class,
    } = this.state;

    if (!fc_class)
      return (
        <div
          style={{
            height: "100vh",
            margin: 0,
            padding: 0,
            width: "100vw",
            backgroundColor: "#000",
          }}
        >
          <Loadindicator />
        </div>
      );

    let { subscription_price, price } = fc_class;

    let btns = new Array(
      {
        title: "Book Now",
        body: (
          <div className="ed_view_link">
            <Checkbox
              title={
                "By submitting this form, you agree to the terms and conditions outlined by First Touch Academy and confirm that all the information you provided is accurate to the best of your knowledge."
              }
              checked={attest}
              _id="attest"
              no_capitalise
              action={() =>
                this.setState({
                  attest: !this.state.attest,
                  message: "",
                })
              }
            />

            <Link
              to=""
              onClick={() => this.proceed()}
              class="btn theme-bg enroll-btn"
            >
              {loading ? "Booking..." : "Book Now"}
              <i className="ti-angle-right"></i>
            </Link>
          </div>
        ),
      },
      {
        title: "Subscribe",
        body: (
          <div className="ed_view_link">
            <Checkbox
              title={
                "By submitting this form, you agree to the terms and conditions outlined by First Touch Academy and confirm that all the information you provided is accurate to the best of your knowledge."
              }
              checked={attest}
              _id="attest"
              no_capitalise
              action={() =>
                this.setState({
                  attest: !this.state.attest,
                  message: "",
                })
              }
            />

            <p className="text-light">
              <b>PS:</b> Please note that payment for subsequent packages will
              be collected automatically via direct debit, which can be
              cancelled in writing at any time.
            </p>

            <Link
              to=""
              onClick={() => this.proceed()}
              class="btn theme-bg enroll-btn"
            >
              {loading ? "Booking..." : "Book Now"}
              <i className="ti-angle-right"></i>
            </Link>
          </div>
        ),
      }
    );

    if (!Number(subscription_price)) btns = btns.slice(0, 1);
    else if (!Number(price)) btns = btns.slice(1);

    return (
      <Loggeduser.Consumer>
        {({ loggeduser }) => {
          this.loggeduser = loggeduser;

          return (
            <div id="main-wrapper">
              <Custom_nav page="book" />
              <Padder />
              <Breadcrumb_banner title="Place your Booking" page="Book" />

              <section>
                <div className="container">
                  <div className="row justify-content-between">
                    <div className="col-xl-6 col-lg-6 col-md-7 col-sm-12">
                      {booked ? (
                        <Booking_successful
                          details={{ ...booked, fullname, email }}
                        />
                      ) : (
                        <form className="">
                          <div class="row">
                            <h5 className="text-light">Booking Form</h5>
                            <br />

                            <Form_divider text="Child's Information" />
                            <div class="col-lg-6 col-md-6 col-sm-12">
                              <div class="form-group">
                                <label className="text-light">
                                  Firstname{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  class="form-control"
                                  type="text"
                                  autoFocus
                                  placeholder="Firstname"
                                  onChange={({ target }) =>
                                    this.setState({
                                      firstname: target.value,
                                      message: "",
                                    })
                                  }
                                  value={firstname}
                                />
                              </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-12">
                              <div class="form-group">
                                <label className="text-light">
                                  Lastname{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  class="form-control"
                                  type="text"
                                  placeholder="Lastname"
                                  onChange={({ target }) =>
                                    this.setState({
                                      lastname: target.value,
                                      message: "",
                                    })
                                  }
                                  value={lastname}
                                />
                              </div>
                            </div>
                            <div class="col-12">
                              <div class="form-group">
                                <label className="text-light">
                                  Date of Birth{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  class="form-control"
                                  type="date"
                                  placeholder="Date of Birth"
                                  onChange={({ target }) =>
                                    this.setState({
                                      date_of_birth: target.value,
                                      message: "",
                                    })
                                  }
                                  value={date_of_birth}
                                />
                              </div>
                            </div>

                            <div className="simple-input col-12 mb-4">
                              <label className="text-light">
                                Gender <span className="text-danger">*</span>
                              </label>
                              <select
                                id="per_session"
                                onChange={(e) =>
                                  this.setState({
                                    gender: e.target.value,
                                    message: "",
                                  })
                                }
                                className="form-control"
                              >
                                <option selected disabled>
                                  -- Select Gender --
                                </option>
                                {genders.map((gender_) => (
                                  <option key={gender_} value={gender_}>
                                    {to_title(gender_.replace(/_/g, " "))}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <br />

                            <Form_divider text="Parent / Guardian" />

                            <div class="col-12">
                              <div class="form-group">
                                <label className="text-light">
                                  Fullname{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  class="form-control"
                                  type="text"
                                  autoFocus
                                  placeholder="Fullname"
                                  onChange={({ target }) =>
                                    this.setState({
                                      fullname: target.value,
                                      message: "",
                                    })
                                  }
                                  value={fullname}
                                />
                              </div>
                            </div>

                            <div class="col-6">
                              <div className="form-group smalls">
                                <label className="text-light">
                                  Email <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="email"
                                  className="form-control"
                                  onChange={({ target }) =>
                                    this.setState({ email: target.value })
                                  }
                                  value={email}
                                />
                              </div>
                            </div>
                            <div class="col-6">
                              <div className="form-group smalls">
                                <label className="text-light">
                                  Phone Number{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  placeholder="Your phone number"
                                  type="text"
                                  className="form-control"
                                  onChange={({ target }) =>
                                    this.setState({
                                      phone: target.value,
                                      message: "",
                                    })
                                  }
                                  value={phone}
                                />
                              </div>
                            </div>

                            <Form_divider text="More Details" />
                            <div class="col-12">
                              <div className="form-group smalls">
                                <label className="text-light">Foot </label>
                                <input
                                  placeholder="Foot"
                                  type="text"
                                  className="form-control"
                                  onChange={({ target }) =>
                                    this.setState({
                                      foot: target.value,
                                      message: "",
                                    })
                                  }
                                />
                              </div>
                            </div>
                            <div class="col-12">
                              <div className="form-group smalls">
                                <label className="text-light">
                                  Are there any medical conditions/allergies we
                                  should be aware of
                                </label>
                                <textarea
                                  placeholder="State allergies"
                                  type="text"
                                  className="form-control"
                                  onChange={({ target }) =>
                                    this.setState({
                                      allergies: target.value,
                                      message: "",
                                    })
                                  }
                                ></textarea>
                              </div>
                            </div>

                            <Checkbox
                              title={
                                "Grant permission for your child to be photographed during sessions for promotional purposes."
                              }
                              _id="permit_photograph"
                              no_capitalise
                              action={() =>
                                this.setState({
                                  permit_photograph:
                                    !this.state.permit_photograph,
                                  message: "",
                                })
                              }
                            />

                            {message ? <Alert_box message={message} /> : null}
                            <div
                              className="form-group smalls"
                              style={{ backgroundColor: "#111" }}
                            >
                              <Accordion
                                defaultActiveKey="0"
                                style={{ backgroundColor: "#111" }}
                              >
                                {btns ? (
                                  btns.map((faq, index) =>
                                    this.item(faq, index)
                                  )
                                ) : (
                                  <Listempty />
                                )}
                              </Accordion>
                            </div>
                          </div>
                        </form>
                      )}
                    </div>

                    <Class no_btn fc_class={fc_class} />
                  </div>
                </div>
              </section>
              <Contact_us_today />
              <Footer />
            </div>
          );
        }}
      </Loggeduser.Consumer>
    );
  }
}

export default Book;
